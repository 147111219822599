import React from 'react';

import { useSpotify } from '../../providers/spotify';
import { useFirebase } from '../../providers/firebase';
import { Button, SearchBar } from '..';

import './navigation.css';

export default function _Navigation() {
    const spotify = useSpotify();
    const firebase = useFirebase();

    const signOut = () => {
        spotify.signOut();
        firebase.signOut();
    };

    return (
        <nav className="nav">
            <SearchBar />
            <Button label="Recent songs" />
            <Button label="Your songs" />
            <Button label="Logout" onClick={signOut} />
            {spotify.user?.display_name}
            <img src={spotify.user?.images[0].url} width="60px" height="60px" alt="Profile" />
        </nav>
    );
}
