import { PrivateUserObject } from './models/PrivateUserObject';

export class SpotifyService {
    private base: string;

    constructor(public accessToken: string, public refreshToken: string) {
        this.base = 'https://api.spotify.com/v1';
    }

    public static login() {
        const url = `https://accounts.spotify.com/authorize?client_id=2c2d40defab448bfaf5a4b307c3f7af3&response_type=code&redirect_uri=${window.origin}/api/authorize`;
        window.location.href = url;
    }

    public async GetCurrentUsersProfile() {
        const endpoint = `${this.base}/me`;
        return await this.request<PrivateUserObject>(endpoint);
    }

    private async request<T>(endpoint: string, options?: RequestInit): Promise<T> {
        options = {
            ...options,
            headers: {
                ...options?.headers,
                Authorization: `Bearer ${this.accessToken}`,
            },
        };

        let response;

        try {
            response = await fetch(endpoint, options);
        } catch (e) {
            console.log(e);
        }

        if (response?.ok) {
            return await response?.json();
        } else {
            throw new Error(`Status code ${response?.status}`);
        }
    }
}
