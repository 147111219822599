import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import Home from './pages/home';
import Login from './pages/login';
import Callback from './pages/callback';
import { useFirebase } from './providers/firebase';
import { useSpotify } from './providers/spotify';

import styles from './App.module.css';

export default function App() {
    const firebase = useFirebase();
    const spotify = useSpotify();

    if (firebase.isLoading || spotify.isLoading) {
        return <div className={styles.app}>Loading...</div>;
    }

    return (
        <div className={styles.app}>
            <Switch>
                <Route path="/callback">
                    <Callback />
                </Route>
                <Route path="/login">
                    {firebase.user && spotify.user && <Redirect to="/" />}
                    <Login />
                </Route>
                <Route path="/">{!firebase.user || !spotify.user ? <Redirect to="/login" /> : <Home />}</Route>
            </Switch>
        </div>
    );
}
