import React from 'react';
import { Navigation } from '../components';

import './home.css';
import { Route, Switch, useLocation } from 'react-router';

export default function Home() {
    const { search } = useLocation();
    const query = search.match(/q=([^&]*)/) || ["", ""];

    return (
        <>
            <Navigation />
            <main>
                <div className="left">
                    <Switch>
                        <Route path="/search">{query[1]}</Route>
                    </Switch>
                </div>
                <div className="right">tags</div>
            </main>
        </>
    );
}
