import React from 'react';
import { Redirect, useLocation } from 'react-router';
import { useSpotify } from '../providers/spotify';

export default function Callback() {
    const { search } = useLocation();
    const {setAccessToken, setRefreshToken} = useSpotify();

    const accessReg = search.match(/access_token=([^&]*)/);
    setAccessToken(accessReg && accessReg[1]);

    const refreshReg = search.match(/refresh_token=([^&]*)/);
    setRefreshToken(refreshReg && refreshReg[1]);

    return <Redirect to="/" />;
}
