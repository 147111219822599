import React from 'react';

import './button.css';

interface ButtonProps {
    label?: string;
    icon?: React.ReactNode;
    onClick?: () => any;
}

export default function _Button({ label, icon, onClick }: ButtonProps) {
    return (
        <button className="button" onClick={onClick}>
            {icon && <span>{icon}</span>}
            {label && <span>{label}</span>}
        </button>
    );
}
