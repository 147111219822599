import React, { useCallback, useContext, useEffect, useState } from 'react';
import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';

interface IFirebaseContext {
    user: app.User | null;
    isLoading: boolean;
    signIn: () => Promise<void>;
    signOut: () => Promise<void>;
}

const no_op = () => null;

const FirebaseContext = React.createContext<IFirebaseContext>({
    user: null,
    isLoading: true,
    signIn: () => new Promise<void>(no_op),
    signOut: () => new Promise<void>(no_op),
});

export const FirebaseProvider = ({ children }: any) => {
    const [isLoading, setLoading] = useState(true);
    const [user, setUser] = useState<app.User | null>(null);

    useEffect(() => {
        try {
            app.app();
        } catch {
            app.initializeApp({
                apiKey: 'AIzaSyC4x9lMb3pEQnWKB7EFUHdQa4hlpDD3W28',
                authDomain: 'ginger-2d49c.firebaseapp.com',
                projectId: 'ginger-2d49c',
                storageBucket: 'ginger-2d49c.appspot.com',
                messagingSenderId: '884091716627',
                appId: '1:884091716627:web:5b3f8284b167c3cee32be6',
            });
        }
    });

    useEffect(() => {
        app.auth().onAuthStateChanged((user) => {
            setUser(user);
            setLoading(false);
        });
    }, []);
    
    const signIn = useCallback(async () => {
        await app.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        const provider = new app.auth.GoogleAuthProvider();
        try {
            await app.auth().signInWithRedirect(provider);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const signOut = useCallback(async () => {
        app.auth().signOut();
    }, []);

    return (
        <FirebaseContext.Provider value={{ user, signIn, isLoading, signOut }}>
            {children}
        </FirebaseContext.Provider>
    );
};

export const useFirebase = () => useContext(FirebaseContext);
