import React from 'react';
import { useSpotify } from '../providers/spotify';
import { useFirebase } from '../providers/firebase';

export default function Login() {
    const firebase = useFirebase();
    const spotify = useSpotify();

    return (
        <>
            {!firebase.user ? (
                <button onClick={() => firebase.signIn()}>Sign in with Google</button>
            ) : (
                <button onClick={() => firebase.signOut()}>Sign out from Google</button>
            )}
            {!spotify.user ? (
                <button onClick={() => spotify.signIn()}>Sign in with Spotify</button>
            ) : (
                <button onClick={() => spotify.signOut()}>Sign out from Spotify</button>
            )}
        </>
    );
}
