import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import SpotifyService from '../../services/spotify';
import { PrivateUserObject } from '../../services/spotify/models/PrivateUserObject';

interface ISpotifyContext {
    service: SpotifyService | null;
    user?: PrivateUserObject;
    isLoading: boolean;
    setAccessToken: React.Dispatch<React.SetStateAction<string | null>>;
    setRefreshToken: React.Dispatch<React.SetStateAction<string | null>>;
    signIn: () => void | null;
    signOut: () => void | null;
}

const no_op = () => null;

const SpotifyContext = createContext<ISpotifyContext>({
    service: null,
    isLoading: true,
    signIn: no_op,
    signOut: no_op,
    setAccessToken: no_op,
    setRefreshToken: no_op,
});

export const SpotifyProvider = ({ children }: any) => {
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [refreshToken, setRefreshToken] = useState<string | null>(null);
    const [service, setService] = useState<SpotifyService | null>(null);
    const [user, setUser] = useState<PrivateUserObject | undefined>();
    const [isLoading, setLoading] = useState(true);

    const signIn = useCallback(() => {
        SpotifyService.login();
    }, []);

    const signOut = useCallback(() => {
        window.localStorage.removeItem('S_ACCESS_TOKEN');
        window.localStorage.removeItem('S_REFRESH_TOKEN');
        setAccessToken(null);
        setRefreshToken(null);
        setService(null);
    }, []);

    useEffect(() => {
        const accessToken_ = window.localStorage.getItem('S_ACCESS_TOKEN');
        const refreshToken_ = window.localStorage.getItem('S_REFRESH_TOKEN');

        if (accessToken_) {
            setAccessToken(accessToken_);
        }

        if (refreshToken_) {
            setRefreshToken(refreshToken_);
        }
    }, []);

    useEffect(() => {
        if (accessToken && refreshToken) {
            setService(new SpotifyService(accessToken, refreshToken));
            window.localStorage.setItem('S_ACCESS_TOKEN', accessToken);
            window.localStorage.setItem('S_REFRESH_TOKEN', refreshToken);
        }
        setLoading(false);
    }, [accessToken, refreshToken]);

    useEffect(() => {
        const getUser = async () => {
            if (service) {
                try {
                    setUser(await service.GetCurrentUsersProfile());
                } catch {}
            }
        };

        getUser();
    }, [service]);

    return (
        <SpotifyContext.Provider value={{ service, user, isLoading, setAccessToken, setRefreshToken, signIn, signOut }}>
            {children}
        </SpotifyContext.Provider>
    );
};

export const useSpotify = () => useContext(SpotifyContext);
