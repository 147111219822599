import React, { useState } from 'react';
import { useHistory } from 'react-router';

import './searchbar.css';

export default function _SearchBar() {
    const [query, setQuery] = useState('');
    const history = useHistory();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        history.push(`/search?q=${query}`);
    };

    const handleChange = (event: any) => setQuery(event.target.value);

    return (
        <form className="search" onSubmit={handleSubmit}>
            <input type="text" placeholder="Search" value={query} onChange={handleChange} />
        </form>
    );
}
